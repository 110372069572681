<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="6" :sm="24" :xs="24">
            <a-form-item label="标题">
              <a-input
                v-model:value.trim="where.title"
                placeholder="请输入标题查询"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24"  >
            <a-form-item label="是否显示:">
              <a-select
                allow-clear
                placeholder="请选择是否显示"
                v-model:value="where.isShow"
              >
                <a-select-option
                  v-for="item in stateDict"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                >
                  {{ item.dictDataName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="6" :sm="24" :xs="24">
            <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
              <a-space>
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="id"
        :datasource="datasource"
        :columns="columns"
        v-model:selection="selection"
        :scroll="{ x: 'max-content' }"
        @change="onTableChange"
      >
        <template #toolbar>
          <a-space>
            <a-button type="primary" @click="openEdit()">
              <template #icon>
                <plus-outlined />
              </template>
              <span>新建</span>
            </a-button>
            <a-button type="primary" danger @click="removeBatch">
              <template #icon>
                <delete-outlined />
              </template>
              <span>删除</span>
            </a-button>
          </a-space>
        </template>
        <template #img="{ record }">
          <img :src="record.url" width="120" height="50" />
        </template>
        <template #action="{ record }">
          <a-space>
            <a @click="openEdit(record)">编辑</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定要删除此项吗？" @confirm="remove(record)">
              <a class="ud-text-danger">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </ud-pro-table>
    </a-card>
    <!-- 编辑 -->
    <BannerEdit v-model:visible="showEdit" :data="current" @done="reload" />
  </div>
</template>

<script>
import * as appBannerApi from '@/api/sys/appBanner'
import BannerEdit from './banners-edit'
import * as dictDataApi from '@/api/sys/dictData'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
export default {
  name: 'bannersIndex',
  components: {
    PlusOutlined,
    DeleteOutlined,
    BannerEdit
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          title: 'appId',
          dataIndex: 'appId'
        },
        {
          title: '标题',
          dataIndex: 'title'
        },
        {
          title: '图片',
          dataIndex: 'url',
          slots: { customRender: 'img' }
        },
        {
          title: '页面',
          dataIndex: 'pageUrl'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: '150px',
          sorter: true,
          customRender: ({ text }) => this.$util.toDateString(text)
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          sorter: true
        },
        {
          title: '是否显示',
          dataIndex: 'isShowName',
          sorter: true
        },
        {
          title: '操作',
          key: 'action',
          width: '150px',
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格排序条件
      order: {},
      // 表格选中数据
      selection: [],
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false,
      // 是否显示用户导入弹窗
      showImport: false,
      categoryList: [],
      // 状态字典项
      stateDict: []
    }
  },
  created() {
    this.queryDataStates()
  },
  methods: {
    datasource(option, callback) {
      appBannerApi
        .page({
          ...this.where,
          ...this.order,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            console.log(res.data)
            res.data.map((item) => {
              if (item.isShow === 0) {
                item.isShowName = '不显示'
              } else if (item.isShow === 1) {
                item.isShowName = '显示'
              }
            })
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 表格分页、排序、筛选改变回调 */
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.order.sort = sorter.field
        this.order.order = { ascend: 'asc', descend: 'desc' }[sorter.order]
      } else {
        this.order = {}
      }
      // this.reload()
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      appBannerApi
        .deleteById(row.id)
        .then((res) => {
          hide()
          if (res.code === 0) {
            this.$message.success(res.msg)
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的项吗?',
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map((d) => d.id) }
          appBannerApi
            .deleteBatch(data)
            .then((res) => {
              hide()
              if (res.code === 0) {
                this.$message.success(res.msg)
                this.reload()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              hide()
              this.$message.error(e.message)
            })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    // 是否显示
    queryDataStates() {
      dictDataApi
        .querydictdata('isShow')
        .then((res) => {
          if (res.code === 0) {
            this.stateDict = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style scoped>
</style>
